export function saveGame(state) {
    localStorage.setItem('sudoku', JSON.stringify(state))
}

export function loadGame() {
    const sudoku = localStorage.getItem('sudoku')
    if (sudoku) {
        return JSON.parse(sudoku)
    }
    return null
}