//Sudoku state
export default {
    table: [],
    current: { x: 0, y: 0 },
    difficulty: "hard",
    inizilized: false,
    solved: [],
    mistakes: 0,
    complete: [],
    win: false,
    money: 0,
    upgrades: [],
    disabledUpgrades: []

}