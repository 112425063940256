<template>
  <ControlItem />
  <div class="container">
    <SudokuBoard />
    <ShopItem />
  </div>
</template>

<script>
import ControlItem from './components/ControlItem.vue';
import ShopItem from './components/ShopItem.vue';
import SudokuBoard from './components/SudokuBoard.vue';

export default {
  name: 'App',
  components: {
    SudokuBoard,
    ControlItem,
    ShopItem
  },
  mounted() {
    window.addEventListener('beforeunload', this.$store.dispatch('saveState'));
  }
}
</script>

<style>
.container {
  display: flex;
  background-color: #f3f4f6;
}
</style>
