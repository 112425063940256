export default {
    setTable(state, table) {
        state.table = table;
    },
    setCurrent(state, { x, y }) {
        state.current.x = x;
        state.current.y = y;
    },
    setDifficulty(state, difficulty) {
        state.difficulty = difficulty;
    },
    setInitialized(state, initialized) {
        state.initialized = initialized;
    },
    setCell(state, { x, y, value }) {
        state.table[x][y].number = value;
    },
    setValid(state, { x, y, isValid }) {
        state.table[x][y].valid = isValid;
    },
    setSolved(state, solved) {
        state.solved = solved;
    },
    setRight(state, { x, y, isRight }) {
        state.table[x][y].right = isRight;
        state.table[x][y].fixed = isRight;
        state.table[x][y].valid = !isRight;
    },
    addMistakes(state) {
        state.mistakes++;
    },
    resetMistakes(state) {
        state.mistakes = 0;
    },
    setAutoCell(state, { x, y, value }) {
        state.table[x][y].number = value;
        state.table[x][y].auto = true;
        state.table[x][y].fixed = true;
    },
    loadGame(state, game) {
        for (const prop in game) {
            state[prop] = game[prop];
        }
    },
    toggleDisabledUpgrade(state, id) {
        const index = state.disabledUpgrades.indexOf(id);
        if (index === -1) {
            state.disabledUpgrades.push(id);
        } else {
            state.disabledUpgrades.splice(index, 1);
        }
    }
}