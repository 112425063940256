<template>
    <div class="shop-container">
        <div v-for="item in items" :key="item.id" class="shop-item" @click="buyItem(item)">
            <div class="item-name">{{ item.name }}</div>
            <div class="item-description">{{ item.description }}</div>
            <div class="item-cost">${{ item.cost }}</div>
        </div>

        <div v-for="item in getBoughtItems" @click="toggleDisabled(item.id)" :key="item.id" class="bought-item">
            <div class="item-name">{{ item.name }}</div>
            <div class="item-description">{{ item.description }}</div>
            <label class="switch" @change="toggleDisabled(item.id)">
                <input type="checkbox" :checked="isUpgradeDisabled(item.id)">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
</template>

<script>

export default {
    name: "ShopItem",
    computed: {
        disabledUpgrades() {
            return this.$store.state.disabledUpgrades;
        },
        items() {
            return this.$store.getters.getNewUpgrades;
        },
        getBoughtItems() {
            return this.$store.getters.getBoughtItems;
        },
    },
    methods: {
        isUpgradeDisabled(id) {
            return !this.$store.getters.isUpgradeDisabled(id);
        },
        buyItem(item) {
            this.$store.dispatch("buyItem", item);
        },
        toggleDisabled(id) {
            this.$store.commit("toggleDisabledUpgrade", id);
        }
    }
}
</script>

<style lang="scss" scoped>
h1 {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    position: relative;
}

.shop-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
    background-color: #f3f4f6;
    border-radius: 8px;
}

.shop-item,
.bought-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 180px;
    height: 50px;
    padding: 15px;
    margin: 10px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        background-color: #b8b8b8;
    }
}

.item-description,
.item-cost {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
}

.item-name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    transform: translateX(14px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
