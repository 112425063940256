<template>
    <div class="sudoku-block">
        <div v-for="(cell, cellIndex) in block" :key="`cell-${cellIndex}`">
            <SudokuNumber :y="calculateX(cellIndex)" :x="calculateY(cellIndex)" />
        </div>
    </div>
</template>

<script>
import SudokuNumber from './SudokuNumber.vue';

export default {
    components: {
        SudokuNumber,
    },
    props: {
        block: Array,
        blockIndex: Number,
    },
    methods: {
        calculateX(cellIndex) {
            return cellIndex % 3 + (this.blockIndex * 3) % 9;
        },
        calculateY(cellIndex) {
            return Math.floor(cellIndex / 3) + Math.floor(this.blockIndex / 3) * 3;
        },
    },
};
</script>

<style scoped>
.sudoku-block {
    border: 1px solid black;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
    padding: 4px;
    box-sizing: border-box;

    width: calc((50vw - 8px - 4 * 2px) / 3);
    height: calc((50vw - 8px - 4 * 2px) / 3);

    max-height: calc((100vh - 110px) /3);

    @media (max-width: 768px) {
        width: calc((100vw - 12px - 4 * 6px) / 3);
        height: calc((100vw - 12px - 4 * 6px) / 3);
    }
}
</style>