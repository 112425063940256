<template>
    <div @click="markTipps" class="input-container">
        <input type="number" max="9" min="1" @keyup.delete="clearCell"
            :class="{ 'auto': auto, 'markedB': markedB, 'markedC': markedC, 'markedR': markedR, 'same': same, 'isRight': isRight, 'isFixed': fixed, 'invalid': isValid }"
            class="sudoku-cell" :value="cellValue" @keydown="clearCell" @keyup="updateCell" :disabled="fixed" />
    </div>
</template>

<script>

export default {
    props: {
        x: Number,
        y: Number,
    },
    computed: {
        auto() {
            return this.cell.auto;
        },
        cell() {
            return this.$store.getters.getCell(this.x, this.y)
        },
        cellValue() {
            return this.cell.number === 0 ? '' : this.cell.number;
        },
        fixed() {
            return this.cell.fixed;
        },
        isValid() {
            return this.cell.valid;
        },
        isRight() {
            return this.cell.right;
        },
        same() {
            return this.cell.same;
        },
        markedB() {
            return this.cell.markedB
        },
        markedC() {
            return this.cell.markedC
        },
        markedR() {
            return this.cell.markedR
        },
    },
    methods: {
        markTipps() {
            this.$store.dispatch('markTipps', {
                x: this.x,
                y: this.y,
            });
        },
        clearCell(event) {
            event.target.value = '';
            this.$store.dispatch('clearCell', {
                x: this.x,
                y: this.y,
            })
        },
        updateCell(event) {
            let value = JSON.parse(JSON.stringify(event.target.value.slice(-1)));

            if (!value.match(/[1-9]/)) {
                event.target.value = '';
                return;
            }

            this.$store.dispatch('updateCell', {
                x: this.x,
                y: this.y,
                value,
            });

            this.$forceUpdate();
        },
    },
};
</script>

<style scoped lang="scss">
.input-container {
    display: inline-block;
    cursor: pointer;
    height: calc(100% - 2 * 2px);

    input[disabled] {
        opacity: 1;
        cursor: pointer;
        pointer-events: none;
    }
}

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.sudoku-cell {
    font-weight: 400;
    color: #000d85;
    text-align: center;
    font-size: 30px;
    font-size: calc(15px + 2vw);
    border: 1px solid #ddd;


    width: calc(100% - 2 * 4px);
    height: 100%;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}


input {
    $darkenFactor: 15%;
    $borderColorFactor: 10%;
    $isRightGreenFactor: 10%;

    $greenColor: #00ff00;

    $baseColors: (
        "markedR": #e6cdad,
        "markedC": #e6adda,
        "markedB": #add8e6,
        "auto": #8c9ef0,
        "invalid": #ff3737,
        "same": #f0e68c,
    );

@each $class, $baseColor in $baseColors {
    &.#{$class} {
        background-color: $baseColor;
        color: #000;
        border: 1px solid darken($baseColor, $borderColorFactor);
    }
}

&.isFixed {
    background-color: #cccccc;
    color: black;
    user-select: none;

    @at-root {
        @each $class, $baseColor in $baseColors {
            #{&}.#{$class} {
                background-color: darken($baseColor, $darkenFactor);
                border-color: darken($baseColor, $darkenFactor + $borderColorFactor);
            }
        }
    }
}

&.isRight {
    background-color: mix($greenColor, #ccffcc, 50%);
    color: black;
    user-select: none;
    border-color: mix($greenColor, #ccffcc, 30%);

    @at-root {
        @each $class, $baseColor in $baseColors {
            #{&}.#{$class} {
                background-color: mix($greenColor, $baseColor, 30%);
                border-color: mix($greenColor, $baseColor, 10%);
            }
        }
    }
}
}
</style>
