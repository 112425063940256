import store from ".";

/**
 * Initializes a Sudoku board as an array of objects with 'number' and 'fixed' properties.
 * 
 * @returns {Array} A 9x9 array of objects for the Sudoku board.
 */
function initializeBoard() {
    return Array.from({ length: 9 }, () =>
        Array(9).fill(0).map(() => ({
            number: 0,
            fixed: true
        }))
    );
}

/**
 * Checks if a number is already present in a specific row.
 * 
 * @param {Array} board The Sudoku board.
 * @param {number} row The row to check.
 * @param {number} num The number to check for.
 * @returns {boolean} True if num is found in the row, false otherwise.
 */
function isInRow(board, row, num) {
    return board[row].some(cell => cell.number === num);
}

/**
 * Checks if a number is already present in a specific column.
 * 
 * @param {Array} board The Sudoku board.
 * @param {number} col The column to check.
 * @param {number} num The number to check for.
 * @returns {boolean} True if num is found in the column, false otherwise.
 */
function isInCol(board, col, num) {
    return board.some(row => row[col].number === num);
}


/**
 * Checks if a number is already present in a specific 3x3 box.
 * 
 * @param {Array} board The Sudoku board.
 * @param {number} row The row of the cell.
 * @param {number} col The column of the cell.
 * @param {number} num The number to check for.
 * @returns {boolean} True if num is found in the 3x3 box, false otherwise.
 */
function isInBox(board, row, col, num) {
    let startRow = row - row % 3,
        startCol = col - col % 3;

    for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
            if (board[startRow + i][startCol + j].number === num) {
                return true;
            }
        }
    }
    return false;
}

/**
 * Checks if it's safe to place a number at a specific board position.
 * 
 * @param {Array} board The Sudoku board.
 * @param {number} row The row of the cell.
 * @param {number} col The column of the cell.
 * @param {number} num The number to place.
 * @returns {boolean} True if it's safe to place num, false otherwise.
 */
export function isSafe(board, row, col, num) {
    return !isInRow(board, row, num) &&
        !isInCol(board, col, num) &&
        !isInBox(board, row, col, num);
}

/**
 * Finds the next empty cell (with a value of 0) in the Sudoku board.
 * 
 * @param {Array} board The Sudoku board.
 * @returns {Array} An array containing the row and column of the next empty cell, or [-1, -1] if the board is full.
 */
function findNextEmptyCell(board) {
    for (let i = 0; i < 9; i++) {
        for (let j = 0; j < 9; j++) {
            if (board[i][j].number === 0) {
                return [i, j];
            }
        }
    }
    return [-1, -1];
}


/**
 * Attempts to solve the Sudoku puzzle using backtracking.
 * 
 * @param {Array} board The Sudoku board.
 * @returns {boolean} True if the Sudoku puzzle is solved, false otherwise.
 */
function solveSudoku(board) {
    let [row, col] = findNextEmptyCell(board);
    if (row === -1) return true;

    for (let num = 1; num <= 9; num++) {
        if (isSafe(board, row, col, num)) {
            board[row][col].number = num;
            if (solveSudoku(board)) {
                return true;
            }
            board[row][col].number = 0;
        }
    }
    return false;
}


/**
 * Fills all the diagonal 3x3 boxes in the Sudoku board to simplify solving.
 * 
 * @param {Array} board The Sudoku board.
 */
function fillDiagonal(board) {
    for (let i = 0; i < 9; i += 3) {
        fillBox(board, i, i);
    }
}

/**
 * Fills a 3x3 box in the Sudoku board with numbers 1-9.
 * 
 * @param {Array} board The Sudoku board.
 * @param {number} row The starting row of the box.
 * @param {number} col The starting column of the box.
 */
function fillBox(board, row, col) {
    let numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
            const randomIndex = Math.floor(Math.random() * numbers.length);
            board[row + i][col + j].number = numbers[randomIndex];
            numbers.splice(randomIndex, 1);
        }
    }
}

/**
 * Generates a Sudoku puzzle and measures the time taken to solve.
 * 
 * @returns {Object} An object containing the board and the solve time.
 */
export function generateSudoku() {
    let board = initializeBoard();
    fillDiagonal(board);
    solveSudoku(board);
    return board;
}

export function generatePuzzel(board, difficulty) {
    if (!difficulty) {
        difficulty = 'hard';
    }
    return createPuzzle(board, difficulty);
}

/**
 * Removes numbers from a solved Sudoku board to create a puzzle.
 * 
 * @param {Array} solvedBoard A fully solved Sudoku board.
 * @param {string} difficulty The desired difficulty level ("easy", "medium", "hard", "very hard").
 * @returns {Array} A Sudoku puzzle of the specified difficulty.
 */
function createPuzzle(solvedBoard, difficulty) {
    const board = JSON.parse(JSON.stringify(solvedBoard));
    let numbersToRemove;

    switch (difficulty.toLowerCase()) {
        case 'easy':
            numbersToRemove = randomNumberBetween(30, 40);
            break;
        case 'medium':
            numbersToRemove = randomNumberBetween(41, 50);
            break;
        case 'hard':
            numbersToRemove = randomNumberBetween(51, 60);
            break;
        case 'very hard':
            numbersToRemove = randomNumberBetween(61, 70);
            break;
        default:
            throw new Error('Invalid difficulty level');
    }

    while (numbersToRemove > 0) {
        const row = Math.floor(Math.random() * 9);
        const col = Math.floor(Math.random() * 9);
        if (board[row][col].number !== 0) {
            board[row][col].number = 0;
            board[row][col].fixed = false;
            numbersToRemove--;
        }
    }

    return board;
}

/**
 * Generates a random number between min and max, inclusive.
 * 
 * @param {number} min The minimum number.
 * @param {number} max The maximum number.
 * @returns {number} A random number between min and max.
 */
function randomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function hasUpgrade(id) {
    return store.state.upgrades.includes(id) && !store.state.disabledUpgrades.includes(id);
}

/**
 * Marks cells that would be invalid if a specified number is placed in its row, column, or box.
 *
 * @param {Array} board The Sudoku board.
 * @param {number} num The number to check for marking.
 */
export function markTips(board, num) {
    resetMarks(board)

    board.forEach((row, rowIndex) => {
        row.forEach((cell, colIndex) => {
            if (cell.fixed && cell.number === num) {
                if (hasUpgrade("same")) {
                    cell.same = true;
                }


                for (let i = 0; i < 9; i++) {
                    if (board[rowIndex][i].number !== num && hasUpgrade("showRows")) {
                        board[rowIndex][i].markedR = true;
                    }
                    if (board[i][colIndex].number !== num && hasUpgrade("showColumns")) {
                        board[i][colIndex].markedC = true;
                    }
                }


                if (!hasUpgrade("showBox")) {
                    return;
                }
                let startRow = rowIndex - rowIndex % 3;
                let startCol = colIndex - colIndex % 3;

                for (let i = 0; i < 3; i++) {
                    for (let j = 0; j < 3; j++) {
                        if (board[startRow + i][startCol + j].number !== num) board[startRow + i][startCol + j].markedB = true;
                    }
                }
            }
        });
    });
}


/**
 * Resets the marked and same properties for all cells in the Sudoku board.
 *
 * @param {Array} board The Sudoku board.
 */
export function resetMarks(board) {
    board.forEach(row => row.forEach(cell => {
        cell.markedB = false;
        cell.markedR = false;
        cell.markedC = false;
        cell.same = false;
    }));
}


/**
 * Checks if all instances of a specific number are used in the Sudoku board.
 * 
 * @param {Array} board The Sudoku board (9x9 array).
 * @param {number} num The number to check for completeness (1-9).
 * @returns {boolean} True if num appears exactly 9 times, false otherwise.
 */
export function checkNumberCompleteness(board, num) {
    let count = 0;

    for (let row = 0; row < board.length; row++) {
        for (let col = 0; col < board[row].length; col++) {
            if (board[row][col].number === num && board[row][col].fixed) {
                count++;
            }
        }
    }

    return count === 9;
}

function checkWin(board) {
    for (let i = 1; i <= 9; i++) {
        if (!checkNumberCompleteness(board, i)) {
            return false;
        }
    }
    return true;
}

function fillLastItemInRow(board, rowIndex) {

    const possibleNumbers = new Set([1, 2, 3, 4, 5, 6, 7, 8, 9]);
    let fillcount = 9;

    board[rowIndex].forEach(cell => {
        if (cell.fixed) {
            possibleNumbers.delete(cell.number);
            if (cell.number !== 0) {
                fillcount--;
            }
        }
    });

    if (fillcount !== 1) {
        return;
    }

    const missingNumber = possibleNumbers.values().next().value;
    const emptyCellIndex = board[rowIndex].findIndex((cell) => cell.number === 0);
    store.dispatch("setAutoCell", { x: rowIndex, y: emptyCellIndex, value: missingNumber })
}

function fillLastItemInColumn(board, colIndex) {
    const possibleNumbers = new Set([1, 2, 3, 4, 5, 6, 7, 8, 9]);
    let fillcount = 9;

    board.forEach((row) => {
        if (row[colIndex].fixed) {
            possibleNumbers.delete(row[colIndex].number);
            if (row[colIndex].number !== 0) {
                fillcount--;
            }
        }
    });

    if (fillcount !== 1) {
        return;
    }

    const missingNumber = possibleNumbers.values().next().value;
    for (let i = 0; i < 9; i++) {
        if (board[i][colIndex].number === 0) {
            store.dispatch("setAutoCell", { x: i, y: colIndex, value: missingNumber })
            break;
        }
    }
}

function fillLastItemInBox(board, boxStartRow, boxStartCol) {
    const possibleNumbers = new Set([1, 2, 3, 4, 5, 6, 7, 8, 9]);
    let fillcount = 9;

    for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
            const cell = board[boxStartRow + i][boxStartCol + j];
            if (cell.fixed) {
                possibleNumbers.delete(cell.number);
                if (cell.number !== 0) {
                    fillcount--;
                }
            }
        }
    }

    if (fillcount !== 1) {
        return;
    }

    const missingNumber = possibleNumbers.values().next().value;

    for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
            const cell = board[boxStartRow + i][boxStartCol + j];
            if (cell.number === 0 && !cell.fixed) {
                store.dispatch("setAutoCell", { x: boxStartRow + i, y: boxStartCol + j, value: missingNumber })
                return;
            }
        }
    }
}

function fillLastDigit(board, digit) {
    const size = 9;
    let missingRow = -1;
    let missingCol = -1;
    let rowCounts = Array(size).fill(0);
    let colCounts = Array(size).fill(0);
    let digitCount = 0;

    for (let i = 0; i < size; i++) {
        for (let j = 0; j < size; j++) {
            if (board[i][j].number === digit && board[i][j].fixed) {
                rowCounts[i]++;
                colCounts[j]++;
                digitCount++;
            }
        }
    }

    if (digitCount !== 8) {
        return
    }


    for (let i = 0; i < size; i++) {
        if (rowCounts[i] === 0) {
            missingRow = i;
        }
        if (colCounts[i] === 0) {
            missingCol = i;
        }
    }

    if (missingRow !== -1 && missingCol !== -1) {
        store.dispatch("setAutoCell", { x: missingRow, y: missingCol, value: digit })
    }
}


export function fillLastMissingItems(board) {
    if (checkWin(board)) {
        store.dispatch("checkNumberCompleteness");
        return;
    }


    if (hasUpgrade("autoRow")) {
        for (let rowIndex = 0; rowIndex < 9; rowIndex++) {
            fillLastItemInRow(board, rowIndex);
        }
    }

    if (hasUpgrade("autoUniqueRow")) {
        for (let rowIndex = 0; rowIndex < 9; rowIndex++) {
            fillUniqueInRow(board, rowIndex);
        }
    }

    if (hasUpgrade("autoColumn")) {
        for (let colIndex = 0; colIndex < 9; colIndex++) {
            fillLastItemInColumn(board, colIndex);
        }
    }

    if (hasUpgrade("autoUniqueColumn")) {
        for (let colIndex = 0; colIndex < 9; colIndex++) {
            fillUniqueInColumn(board, colIndex);
        }
    }


    if (hasUpgrade("autoBox")) {
        for (let boxStartRow = 0; boxStartRow < 9; boxStartRow += 3) {
            for (let boxStartCol = 0; boxStartCol < 9; boxStartCol += 3) {
                fillLastItemInBox(board, boxStartRow, boxStartCol);
            }
        }
    }

    if (hasUpgrade("autoUniqueBox")) {
        for (let boxStartRow = 0; boxStartRow < 9; boxStartRow += 3) {
            for (let boxStartCol = 0; boxStartCol < 9; boxStartCol += 3) {
                fillUniqueInBox(board, boxStartRow, boxStartCol);
            }
        }
    }

    if (hasUpgrade("autoKind")) {
        for (let colIndex = 1; colIndex <= 9; colIndex++) {
            fillLastDigit(board, colIndex);
        }
    }

    if (hasUpgrade("autoUniqueFill")) {
        for (let boxStartRow = 0; boxStartRow < 9; boxStartRow += 3) {
            for (let boxStartCol = 0; boxStartCol < 9; boxStartCol += 3) {
                fillUniqueNumberInEachCell(board, boxStartRow, boxStartCol);
            }
        }
    }

}

function fillUniqueInBox(board, boxStartRow, boxStartCol) {
    const boxSize = 3;
    const possibleNumbers = new Set([1, 2, 3, 4, 5, 6, 7, 8, 9]);

    for (let i = 0; i < boxSize; i++) {
        for (let j = 0; j < boxSize; j++) {
            const num = board[boxStartRow + i][boxStartCol + j].number;
            if (num !== 0) {
                possibleNumbers.delete(num);
            }
        }
    }

    possibleNumbers.forEach(number => {
        let possiblePositions = [];

        for (let i = 0; i < boxSize; i++) {
            for (let j = 0; j < boxSize; j++) {
                const cell = board[boxStartRow + i][boxStartCol + j];
                if (cell.number === 0 && canNumberGoHereBox(board, boxStartRow + i, boxStartCol + j, number)) {
                    possiblePositions.push({ row: boxStartRow + i, col: boxStartCol + j });
                }
            }
        }

        if (possiblePositions.length === 1) {
            const pos = possiblePositions[0];
            store.dispatch("setAutoCell", { x: pos.row, y: pos.col, value: number });
        }
    });
}

function fillUniqueInRow(board, rowIndex) {
    const gridSize = 9;
    const possibleNumbers = new Set([1, 2, 3, 4, 5, 6, 7, 8, 9]);

    for (let colIndex = 0; colIndex < gridSize; colIndex++) {
        const num = board[rowIndex][colIndex].number;
        if (num !== 0) {
            possibleNumbers.delete(num);
        }
    }

    possibleNumbers.forEach(number => {
        let possiblePositions = [];

        for (let colIndex = 0; colIndex < gridSize; colIndex++) {
            const cell = board[rowIndex][colIndex];
            if (cell.number === 0 && canNumberGoHereRow(board, rowIndex, colIndex, number)) {
                possiblePositions.push(colIndex);
            }
        }

        if (possiblePositions.length === 1) {
            store.dispatch("setAutoCell", { x: rowIndex, y: possiblePositions[0], value: number });
        }
    });
}

function fillUniqueInColumn(board, colIndex) {
    const gridSize = 9;
    const possibleNumbers = new Set([1, 2, 3, 4, 5, 6, 7, 8, 9]);

    for (let rowIndex = 0; rowIndex < gridSize; rowIndex++) {
        const num = board[rowIndex][colIndex].number;
        if (num !== 0) {
            possibleNumbers.delete(num);
        }
    }

    possibleNumbers.forEach(number => {
        let possiblePositions = [];

        for (let rowIndex = 0; rowIndex < gridSize; rowIndex++) {
            const cell = board[rowIndex][colIndex];
            if (cell.number === 0 && canNumberGoHereColumn(board, rowIndex, colIndex, number)) {
                possiblePositions.push(rowIndex);
            }
        }

        if (possiblePositions.length === 1) {
            store.dispatch("setAutoCell", { x: possiblePositions[0], y: colIndex, value: number });
        }
    });
}

function canNumberGoHereColumn(board, row, col, number) {
    const gridSize = 9;
    for (let j = 0; j < gridSize; j++) {
        if (board[row][j].number === number) {
            return false;
        }
    }

    let boxStartRow = row - row % 3;
    let boxStartCol = col - col % 3;
    for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
            if (board[boxStartRow + i][boxStartCol + j].number === number) {
                return false;
            }
        }
    }

    return true;
}

function canNumberGoHereBox(board, row, col, number) {
    const gridSize = 9;
    for (let k = 0; k < gridSize; k++) {
        if (board[row][k].number === number || board[k][col].number === number) {
            return false;
        }
    }
    return true;
}

function canNumberGoHereRow(board, row, col, number) {
    const gridSize = 9;
    for (let i = 0; i < gridSize; i++) {
        if (board[i][col].number === number) {
            return false;
        }
    }

    let boxStartRow = row - row % 3;
    let boxStartCol = col - col % 3;
    for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
            if (board[boxStartRow + i][boxStartCol + j].number === number) {
                return false;
            }
        }
    }

    return true;
}

function fillUniqueNumberInEachCell(board, boxStartRow, boxStartCol) {
    const boxSize = 3;
    const gridSize = 9;

    for (let i = 0; i < boxSize; i++) {
        for (let j = 0; j < boxSize; j++) {
            const cellRow = boxStartRow + i;
            const cellCol = boxStartCol + j;
            const cell = board[cellRow][cellCol];

            if (cell.number === 0) {
                let possibleNumbers = new Set([1, 2, 3, 4, 5, 6, 7, 8, 9]);

                for (let num = 1; num <= gridSize; num++) {
                    if (!canNumberGoHereRow(board, cellRow, cellCol, num) ||
                        !canNumberGoHereColumn(board, cellRow, cellCol, num) ||
                        !canNumberGoHereBox(board, cellRow, cellCol, num)) {
                        possibleNumbers.delete(num);
                    }
                }

                if (possibleNumbers.size === 1) {
                    const uniqueNumber = possibleNumbers.values().next().value;
                    store.dispatch("setAutoCell", { x: cellRow, y: cellCol, value: uniqueNumber });
                }
            }
        }
    }
}
