//Sudoku getters
import upgrades from "../data/upgrades.json";

export default {
    getCell: (state) => (x, y) => {
        return state.table[x][y]
    },
    getNewUpgrades: (state) => {
        return upgrades.filter(upgrade => !state.upgrades.includes(upgrade.id))
    },
    getBoughtItems: (state) => {
        return state.upgrades.map(id => upgrades.find(upgrade => upgrade.id === id))
    },
    isUpgradeDisabled: (state) => (id) => {
        return state.disabledUpgrades.includes(id);
    }
}