<template>
    <div class="sudoku-board" v-if="initialized" @click.stop>
        <SudokuBlock v-for="(block, blockIndex) in sudokuBlocks" :key="`block-${blockIndex}`" :block="block"
            :blockIndex="blockIndex" />
        <div class="win" v-if="win">You have Won the Game</div>

    </div>
</template>

<script>
import SudokuBlock from './SudokuBlock.vue';

export default {
    components: {
        SudokuBlock,
    },
    computed: {
        win() {
            return this.$store.state.win;
        },
        sudoku() {
            return this.$store.state.table;
        },
        initialized() {
            return this.$store.state.initialized;
        },
        sudokuBlocks() {
            const blocks = [];
            const blockSize = Math.sqrt(this.sudoku.length);
            for (let i = 0; i < this.sudoku.length; i += blockSize) {
                for (let j = 0; j < this.sudoku.length; j += blockSize) {
                    const block = [];
                    for (let k = i; k < i + blockSize; k++) {
                        for (let l = j; l < j + blockSize; l++) {
                            block.push(this.sudoku[k][l]);
                        }
                    }
                    blocks.push(block);
                }
            }
            return blocks;
        },
    },
    methods: {
        outsideClick() {
            this.$store.dispatch('resetMarks');
        }
    },
    mounted() {
        this.$store.dispatch('loadGame');
        document.addEventListener('click', this.outsideClick);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.outsideClick);
    },
};
</script>

<style scoped>
.sudoku-board {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
    border: 3px solid black;
    width: 50vw;
    height: 50vw;

    max-height: calc(100vh - 100px);
}

.win {
    position: absolute;
    top: 40%;
    left: calc(60% - 200px);
    width: 200px;



    padding: 20px;
    background-color: #4CAF50;
    color: white;

    font-size: 30px;
    font-weight: bold;
}



@media (max-width: 768px) {
    .sudoku-board {
        width: 100vw;
        height: 100vw;
        max-width: 100%;
    }
}
</style>