<template>
    <div class="control">


        <div>
            <select v-model="difficulty">
                <option value="easy">Easy</option>
                <option value="medium">Medium</option>
                <option value="hard">Hard</option>
                <option value="very hard">Very Hard</option>
            </select>

            <button @click="resetGame">Reset Game</button>
        </div>

        <button @click="saveGame">Save</button>


        <div class="box">
            <div v-show="showMoney" class="money">Money: {{ money }}</div>
            <div class="mistakes" :style="{ 'background-color': mistakesColor }">Mistakes: {{ mistakes }}</div>
        </div>

        <div v-show="showCompleted" class="cNumbers">Completed Numbers:
            <div class="sudoku-cell" v-for="(c) in completedNumbers" :key="'num-' + c">
                {{ c }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        resetGame() {
            this.$store.dispatch('resetGame', this.difficulty);
        },
        saveGame() {
            this.$store.dispatch('saveState');
        }
    },
    computed: {
        showMoney() {
            return this.$store.state.money > 0;
        },
        showCompleted() {
            return this.completedNumbers.length > 0 && this.$store.state.upgrades.includes('completed') && this.$store.state.disabledUpgrades.includes('completed') === false;
        },

        completedNumbers() {
            return this.$store.state.complete;
        },
        mistakes() {
            return this.$store.state.mistakes;
        },
        money() {
            return this.$store.state.money.toFixed(2) + "$";
        },
        mistakesColor() {
            let maxMistakes = 10;

            if (this.mistakes < Math.floor(maxMistakes / 2)) {
                return '#4CAF50';
            } else if (this.mistakes < maxMistakes) {
                return '#FFA500';
            } else {
                return '#af4c4c';
            }
        }
    },
    data() {
        return {
            difficulty: 'hard',
        }
    },
}
</script>

<style lang="scss" scoped>
.sudoku-cell {
    font-weight: 400;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 30px;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
}

.control {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .money {
        padding: 10px;
        background-color: #4CAF50;
        color: white;
    }

    .box {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

    }

    button {
        margin: 5px;
        padding: 10px;
        background-color: #af4c4c;
        color: white;
        border: none;
        cursor: pointer;
    }

    select {
        margin: 5px;
        padding: 10px;
        background-color: #4c5baf;
        color: white;
        border: none;
        cursor: pointer;
    }

    div {
        display: flex;
        align-items: center;
    }

    .cNumbers {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        background-color: #4CAF50;
        color: white;
    }

    .mistakes {
        padding: 10px;
        background-color: #4CAF50;
        color: white;
    }
}
</style>