
import { checkNumberCompleteness, fillLastMissingItems, generatePuzzel, generateSudoku, isSafe, markTips, resetMarks } from './generate';
import { loadGame, saveGame } from './save';
export default {
    initialize({ commit }, difficulty = null) {
        const result = generateSudoku();
        commit('setSolved', result)
        const table = generatePuzzel(result, difficulty);
        commit('setTable', table);
        commit('setInitialized', true);
    },
    saveState({ state }) {
        saveGame(state);
    },
    loadGame({ commit, dispatch }) {
        const game = loadGame();
        if (!game) {
            dispatch("initialize");
        } else {
            commit('loadGame', game)
        }
    },
    resetMarks({ state }) {
        resetMarks(state.table)
    },
    markTipps({ state }, { x, y }) {
        if (state.table[x][y].number === 0) {
            return
        }
        if (state.table[x][y].same) {
            resetMarks(state.table)
            return
        }

        markTips(state.table, state.table[x][y].number)
    },
    resetGame({ state, commit, dispatch }, difficulty) {
        commit('setInitialized', false);
        commit('resetMistakes');
        state.difficulty = difficulty;
        state.complete = [];
        state.win = false;
        dispatch('initialize', difficulty);
        dispatch("checkLastNumber")
        dispatch("checkNumberCompleteness")
        saveGame(state);
    },
    clearCell({ commit, dispatch }, { x, y }) {
        commit('setCell', { x, y, value: 0 });
        commit('setValid', { x, y, isValid: false })
        dispatch("resetMarks")
    },
    updateCell({ commit, dispatch }, { x, y, value }) {
        value = parseInt(value.toString().charAt(0));

        if (value == 0 || isNaN(value) || value < 0 || value > 9) {
            commit('setValid', { x, y, isValid: true })
            commit('setCell', { x, y, value: 0 });
            return
        }

        dispatch("checkCell", { x, y, value })
    },
    checkCell({ state, commit, dispatch }, { x, y, value }) {
        dispatch("checkValid", { x, y, val: value })
        dispatch("checkRight", { x, y, val: value })

        commit('setCell', { x, y, value });

        if (state.table[x][y].valid) {
            return
        }


        dispatch("checkLastNumber")
        dispatch("markTipps", { x, y })
        dispatch("checkNumberCompleteness")

        dispatch("saveState")
    },
    setAutoCell({ commit, dispatch, state }, { x, y, value }) {
        const isRight = state.solved[x][y].number === value;

        if (!isRight) {
            return
        }

        commit('setAutoCell', { x, y, value })
        dispatch("checkLastNumber")
        dispatch("checkNumberCompleteness")
        dispatch("saveState")
    },

    checkNumberCompleteness({ state, dispatch }) {
        for (let i = 1; i <= 9; i++) {
            let result = checkNumberCompleteness(state.table, i)
            if (result) {
                if (!state.complete.includes(i)) {
                    state.complete.push(i);
                    state.complete.sort();
                }
                if (state.complete.length === 9) {
                    dispatch("winGame")
                }
            }
        }
    },
    winGame({ state }) {
        state.win = true;

        const difficultyMultiplier = {
            easy: 1,
            medium: 2,
            hard: 3,
            'very hard': 4
        };
        const baseReward = 100;
        let reward = baseReward * difficultyMultiplier[state.difficulty];
        const penalty = state.mistakes * 10;
        state.money += Math.max(reward - penalty, 0);
    },


    checkLastNumber({ state }) {
        fillLastMissingItems(state.table)
    },
    checkValid({ state, commit }, { x, y, val }) {
        const isValid = !isSafe(state.table, x, y, val);
        commit("setValid", { x, y, isValid })
    },
    checkRight({ state, commit }, { x, y, val }) {
        const isRight = state.solved[x][y].number === val;
        if (!isRight) commit('addMistakes');
        commit("setRight", { x, y, isRight })
    },
    buyItem({ state }, item) {
        if (state.money >= item.cost && !state.upgrades.includes(item.id)) {
            state.money -= item.cost;
            state.upgrades.push(item.id);
        }
    }
}